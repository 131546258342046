import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Select from "react-select";

import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import * as ROLES from "../../constants/roles";
import { G_API_KEY } from "../../config";
import Script from "react-load-script";
const AddCookModal = ({
  currentUserMember,
  showAddCookModal,
  setShowAddCookModal,
  history,
  roles,
  cookData,
}) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobile2, setMobile2] = useState("");

  const [nameError, setNameError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [levelError, setLevelError] = useState("");
  const [mobile2Error, setMobile2Error] = useState("");

  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);
  const [cookAlreadyExists, setCookAlreadyExists] = useState(false);
  const [existingCookId, setExistingCookId] = useState(null);
  const [markHotLead, setMarkHotLead] = useState(null);
  const [levelFilter, setLevelFilter] = useState("");
  const [services, setServices] = useState([]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.style?.color, // Apply color based on the style provided in the option
      color: state.isSelected ? "white" : state.data.style?.color, // Apply grey text color when selected, otherwise use provided color
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'darkGray', // Placeholder color set to red
    }),
  };

  const getAllServices = () => {
    axios(`/admin/cook/levels`).then((res) => {
      setServices(res.data.levels);
    });
  };
  const serviceOp = services?.map((item) => ({
    value: item,
    label: item,
  }));
  // const serviceOptions = serviceOp
  //   ? [{ label: "Level*", value: "" }, ...serviceOp]
  //   : "";
  const validateAddCook = () => {
    let nameError = "";
    let mobileError = "";
    let mobile2Error = "";
    let levelError = "";

    if (!name) {
      nameError = "Name cannot be blank";
    }
    if (!mobile) {
      mobileError = "Mobile cannot be blank";
    }
    if (mobile.length < 10) {
      mobileError = "Must be 10 digits long";
    }

    if (mobile2.length > 0 && mobile2.length < 10) {
      mobile2Error = "Must be 10 digits long";
    }

    if(!levelFilter){
      levelError = "Level must be selected"
    }
    if (nameError || mobileError || mobile2Error || levelError) {
      setNameError(nameError);
      setMobileError(mobileError);
      setMobile2Error(mobile2Error);
      setLevelError(levelError);
      return false;
    }
    return true;
  };
  useEffect(()=> {
    if(name){
      setNameError("");
    }
    if(mobile && mobile.length=== 10){
      setMobileError("")
    }
    if(mobile2.length === 10){
      setMobile2Error("")
    }
    if(levelFilter){
      setLevelError("")
    }
  }, [name, mobile, mobile2, levelFilter])
  useEffect(() => {
    setName(cookData?.name || "");
    setMobile(cookData?.mobile || "");
    setMarkHotLead(cookData?.can_be_onboard_status || null);
    getAllServices();
  }, [cookData, showAddCookModal]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateAddCook();
    if (isValid) {
      setLoading(true);

      axios({
        method: "post",
        url: `/user/cook/register`,
        data: {
          name,
          status: markHotLead,
          mobile: String(mobile),
          ...(mobile2 !== "" && {
            mobile_2: mobile2,
          }),
          level: levelFilter ? levelFilter : "",
          city: cityName ? cityName : "",
          state: state ? state : "",
          country: country ? country : "",
        },
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.accessToken,
        },
      })
        .then((response) => {
          if(currentUserMember) {
            alert("Partner Added SuccessFully");
          }
          else if (response.status === 200 && !currentUserMember) {
            window.open(`/edit-cook/${response.data.data.cook_details.id}`);
          }
        })
        .then(() => handleClose())
        .catch((error) => {
          let error_msg = "";

          if (error.response?.status === 400) {
            error_msg += error.response?.data?.errors.body[0].message;
          } else if (error.response?.status === 409) {
            error_msg = error?.response?.data?.data?.message;
            setCookAlreadyExists(true);
            setExistingCookId(error.response?.data?.data?.cook_details?.id);
          } else {
            error_msg = error?.response?.data?.data.message;
          }
          if(error.response.status !== 401) {
            alert(error_msg)
          }
          // alert(error.response?.data?.errors ? error.response?.data?.errors?.body[0]?.message : error.response?.data?.message )
          setApiError(error_msg);
        })
        .finally(() => {
          setLoading(false);
          // setName("");
          // setMobile("");
          // setMarkHotLead(null);

          setNameError("");
          setMobileError("");
          setMobile2Error("");
        });
    }
  };
  const handleClose = () => {
    setShowAddCookModal(false);
    setName("");
    setMobile("");
    setCityName("");
    setState("");
    setCountry("");
    setLevelFilter("");
    setNameError("");
    setMobileError("");
    setMobile2("");
    setMobile2Error("");

    setApiError("");
    setCookAlreadyExists("");
    setExistingCookId("");
    setMarkHotLead(null);
  };
  const [cityName, setCityName] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const autocompleteRef = useRef(null);
  const handleCityChange = (value) => {
    setCityName(value);
    if (value === "") {
      setState("");
      setCountry("");
    }
  };
  const handleScriptLoad = () => {
    // Declare Options For Autocomplete
    const options = {
      types: ["(cities)"],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    autocompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("componentId"),
      options
    );

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components and formatted
    // address.
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);

    // Fire Event when a suggested name is selected
    autocompleteRef.current.addListener("place_changed", handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    // Extract City From Address Object
    const addressObject = autocompleteRef.current.getPlace();
    const address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      address.forEach(({ long_name, types }) => {
        switch (types[0]) {
          case "locality":
            setCityName(long_name);
            break;
          case "administrative_area_level_1":
            setState(long_name);
            break;

          case "country":
            setCountry(long_name);
            break;
        }
      });
    }
  };

  return (
    <Modal show={showAddCookModal} onHide={() => handleClose()} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Partner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Form.Control
            type="text"
            placeholder="NAME*"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            // className="form-group"
          />
          <div style={{ fontSize: 12, color: "red" }}>{nameError}</div>
          <br />
          <Form.Control
            type="number"
            placeholder="MOBILE*"
            maxLength="10"
            minLength="10"
            name="mobile"
            value={mobile}
            onKeyDown={(e) =>
              (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
            }
            onChange={(e) => {
              return e.target.value.length > 10
                ? null
                : setMobile(e.target.value);
            }}
          />
          <div style={{ fontSize: 12, color: "red" }}>{mobileError}</div>
          <br />
          <Form.Control
            type="number"
            placeholder="MOBILE 2"
            maxLength="10"
            minLength="10"
            name="mobile2"
            value={mobile2}
            onKeyDown={(e) =>
              (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
            }
            onChange={(e) => {
              return e.target.value.length > 10
                ? null
                : setMobile2(e.target.value);
            }}
          />
          <div style={{ fontSize: 12, color: "red" }}>{mobile2Error}</div>
          <br />
          {/* <Form.Control
            as="select"
            onChange={(e) => {
              setLevelFilter(e.target.value);
            }}
            value={levelFilter}
            name="levelFilter"
          >
            {serviceOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}{" "}
          </Form.Control> */}
          <Select
            id="levelFilter"
            options={serviceOp.map((option) => ({
              value: option.value,
              label: option.label,
            }))}
            onChange={(selected) => setLevelFilter(selected?.value)}
            value={serviceOp.find((option) => option.value === levelFilter) || null}
            // isSearchable={true}
            placeholder="Select Level*"
            // className="form-select form-select-sm"
            styles={customStyles}
          />
          <div style={{ fontSize: 12, color: "red" }}>{levelError}</div>
          <br />
          <div className="form-group">
            <input
              id="componentId"
              type="text"
              value={cityName}
              placeholder="Select City"
              name="name"
              onChange={({ target: { value } }) => {
                handleCityChange(value);
              }}
              className="form-control"
            />
          </div>

          <Row>
            <Col>
              <Form.Control
                type="text"
                name="state"
                placeholder="State"
                value={state}
                className="text-muted"
                disabled={true}
              />
            </Col>
            <Col>
              <Form.Control
                type="text"
                name="country"
                placeholder="Country"
                value={country}
                className="text-muted"
                disabled={true}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={2.5}>
              <h5 className="mb-2 pl-2 ">Hot Lead?</h5>
            </Col>

            <Col>
              <input
                type="radio"
                name="MarkHotLead"
                checked={markHotLead === "hot"}
                value={"hot"}
                onChange={() => setMarkHotLead("hot")}
              />{" "}
              Yes
              <input
                className="ml-1"
                type="radio"
                name="markAsHotLead"
                checked={markHotLead === "dead"}
                value="not_hot"
                onChange={() => setMarkHotLead("dead")}
              />{" "}
              No
            </Col>
          </Row>
          {/* <Form.Control
            type="number"
            placeholder="Referred By"
            maxLength="10"
            minLength="10"
            name="referredBy"
            value={referredBy}
            onKeyDown={(e) =>
              (e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()
            }
            onChange={(e) => {
              return e.target.value.length > 10
                ? null
                : setReferredBy(e.target.value);
            }}
          /> */}
          {/* <div style={{ fontSize: 12, color: "red" }}>{referredByError}</div> */}

          <br />
          <br />
          {!levelFilter && apiError && (
            <span className="text-danger">{apiError}</span>
          )}
          {cookAlreadyExists ? (
            <>
              <span className="text-dark">
                This Partner is already Registered.{" "}
              </span>
              <span>
                {roles.includes(ROLES.ADMIN) ||
                roles.includes(ROLES.SUPERADMIN) ? (
                  <a
                    href={`/edit-cook/${existingCookId}`}
                    className="font-weight-bolder"
                    target="_blank"
                  >
                    Go to profile
                  </a>
                ) : (
                  <button
                    className="btn btn-link p-0"
                    onClick={() =>
                      alert(
                        "Only Super Admins / Admins can view / edit this Partner"
                      )
                    }
                  >
                    Go to profile
                  </button>
                )}
              </span>
            </>
          ) : (
            ""
          )}
          <Button
            variant="warning"
            type="submit"
            style={{ marginTop: "20px" }}
            block
            disabled={loading}
          >
            Add Partner
            {loading ? "..." : null}
          </Button>
        </form>
      </Modal.Body>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${G_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
    </Modal>
  );
};

export default withRouter(AddCookModal);
