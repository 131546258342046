import React, { Suspense, lazy, useState } from 'react';

import Script from 'react-load-script';

// Constants
import * as ROUTES from './constants/routes';
import * as ROLES from './constants/roles';

// Utilities
import ScrollToTop from './utils/ScrollToTop';
import FullPageLoader from './utils/FullPageLoader';
import PrivateRoute from './utils/PrivateRoute';
import { initializeAnalytics, onMessageListener } from './utils/firebase';

// HOCs
import withAuthentication from './HOCs/Session/withAuthentication';
import withAxios from './HOCs/Interceptor/withAxios';

// libraries
import {
  BrowserRouter as Router,
  Route,
  // Switch,
  Redirect,
} from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Toast } from 'react-bootstrap';

// Components
import Sidebar from './components/SidebarNew';
import SignIn from './components/SignIn';
import Navigation from './components/Navigation';

import './css/globalStyles.css';
import DekodeWidget from './components/Decode';

const EditCook = lazy(() => import('./pages/EditCook'));
const HelpersDashboard = lazy(() => import('./pages/HelpersDashboard'));
const SalesDashboard = lazy(() => import('./pages/SalesDashboard'));
const CoinDashboard = lazy(() => import('./pages/CoinDashboard'));
const HotelsDashboard = lazy(() => import('./pages/HotelsDashboard'));
const CakesDashboard = lazy(() => import('./pages/CakesDashboard'));
const DecorationsDashboard = lazy(() => import('./pages/DecorationsDashboard'));
const DishesDashboard = lazy(() => import('./pages/DishesDashboard'));
const LoginsDashboard = lazy(() => import('./pages/LoginsDashboard'));
const BrandsDashboard = lazy(() => import('./pages/BrandsDashboard'));
const ShopsDashboard = lazy(() => import('./pages/ShopsDashboard'));
const PenaltiesDashboard = lazy(() => import('./pages/PenaltiesDashboard'));
const Dish = lazy(() => import('./pages/DishesDashboard/DishDetailsPage'));
const IngredientsDashboard = lazy(() => import('./pages/IngredientsDashboard'));
const AppliancesDashboard = lazy(() => import('./pages/AppliancesDashboard'));
const CuisinesAndCombosDashboard = lazy(
  () => import('./pages/CuisinesAndCombosDashboard')
);
const Top25Cooks = lazy(() => import('./pages/Top25Cooks'));
const Login = lazy(() => import('./pages/Login'));
const UserAccess = lazy(() => import('./pages/UserAccess'));

const Footer = lazy(() => import('./components/FooterNew'));

const CookEditHistory = lazy(() => import('./pages/CookEditHistory'));

const HomePage = lazy(() => import('./pages/HomePage'));
const AssignedCooks = lazy(() => import('./pages/AssignedCooks'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const CookStages = lazy(() => import('./pages/CookStages'));
const BookingsDashboard = lazy(() => import('./pages/BookingsDashboard'));
const SupplyDashboard = lazy(() => import('./pages/SupplyDashboard'));
const BookingCalendar = lazy(() => import('./pages/BookingCalendar'));
const MonthlyView = lazy(() => import('./pages/MonthlyView'));
const CouponsDashboard = lazy(() => import('./pages/CouponsDashboard'));
const KitsDashboard = lazy(() => import('./pages/KitsDashboard'));
const FindACook = lazy(() => import('./pages/FindACook'));
const PicturesDashboard = lazy(() => import('./pages/PicturesDashboard'));
const ContactDashboard = lazy(() => import('./pages/ContactDashboard'));
const HowzzatDashboard = lazy(() => import('./pages/HowzzatDashboard'));
const BEEHIVE = lazy(() => import('./pages/BEEHIVE'));
const STATS = lazy(() => import('./pages/StatsDashboard'));

export const App = (props) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({
    title: '',
    body: '',
    image: '',
    data: {},
  });
  initializeAnalytics();
  onMessageListener()
    .then((payload) => {
      setShowNotification(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        image: payload.notification.image,
        data: payload.data,
      });
    })
    .catch((err) => console.log('failed: ', err));
  return (
    <Router>
      <ScrollToTop />
      {!props.authUser ? (
        <>
          <Route exact path={ROUTES.LANDING} component={SignIn} />
          <Route path='*' render={() => <Redirect to={ROUTES.LANDING} />} />
        </>
      ) : (
        <div>
          {
            <>
              <DekodeWidget userId={props.authUser.id} />
            </>
          }

          <FullPageLoader />
          <Toast
            onClose={() => setShowNotification(false)}
            show={showNotification}
            delay={15000}
            autohide
            animation
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              minWidth: 200,
              zIndex: 1000000,
            }}
          >
            <Toast.Header>
              {notification.image && (
                <img
                  src={notification.image}
                  className='rounded mr-2'
                  width='30'
                  alt=''
                />
              )}
              <strong className='mr-auto'>{notification.title}</strong>
              <small>just now</small>
            </Toast.Header>
            <Toast.Body>
              {notification.body}
              <a
                href={`/booking-dashboard?customer_query=${notification.data.booking_id}`}
                target='_blank'
                rel='noreferrer'
              >
                Open
              </a>
            </Toast.Body>
          </Toast>
          <Navigation />

          {/* <hr /> */}
          <div>
            {/* <!-- Begin page --> */}
            <div className='wrapper mm-show'>
              {/* <!-- ========== Left Sidebar Start ========== --> */}
              <Sidebar />
              {/* <!-- Left Sidebar End --> */}
              <Suspense fallback={<div>Loading...</div>}>
                <div className='content-page'>
                  <Route
                    exact
                    path={ROUTES.LOGIN}
                    render={(props) => <Login {...props} />}
                  />
                  <PrivateRoute
                    exact
                    path={[`${ROUTES.HOME}`, `${ROUTES.LANDING}`]}
                    component={HomePage}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.MEMBER,
                      ROLES.HR,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.DIGITALMARKETER,
                      ROLES.FINANCE
                    ]}
                  />
                  <Route exact path={ROUTES.EDITCOOK} component={EditCook} />

                  <PrivateRoute
                    exact
                    path={ROUTES.FINDCOOK}
                    component={FindACook}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.COOKSTAGES}
                    component={CookStages}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.ASSIGNEDCOOKS}
                    component={AssignedCooks}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                    ]}
                  />
                  <Route
                    exact
                    path={ROUTES.CHANGEPASSWORD}
                    component={ChangePassword}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.SALESDASHBOARD}
                    component={SalesDashboard}
                    roles={[
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,

                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.BOOKINGSDASHBOARD}
                    component={BookingsDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.SUPERADMIN,
                      ROLES.JRADMIN,
                      ROLES.DIGITALMARKETER,
                      ROLES.FINANCE
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.SUPPLYDASHBOARD}
                    component={SupplyDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.FINANCE
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.PICTURESDASHBOARD}
                    component={PicturesDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.DIGITALMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.HOWZZAT}
                    component={HowzzatDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.BEEHIVE}
                    component={BEEHIVE}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.DIGITALMARKETER,
                      ROLES.MEMBER,
                      ROLES.BASIC,
                      ROLES.HR,
                      ROLES.FINANCE
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.STATS}
                    component={STATS}
                    roles={[ROLES.SUPERADMIN]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.USERACCESS}
                    component={UserAccess}
                    roles={[ROLES.SUPERADMIN, ROLES.HR]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.DISHDASHBOARD}
                    component={DishesDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.ADMIN,
                      ROLES.JRADMIN,
                      ROLES.DIGITALMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.DISH}
                    component={Dish}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.DIGITALMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={[
                      `${ROUTES.INGREDIENTSDASHBOARD}/:ingredient_id`,
                      ROUTES.INGREDIENTSDASHBOARD,
                    ]}
                    component={IngredientsDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.JRADMIN,
                      ROLES.MARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={[
                      `${ROUTES.APPLIANCESDASHBOARD}/:appliance_id`,
                      ROUTES.APPLIANCESDASHBOARD,
                    ]}
                    component={AppliancesDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.JRADMIN,
                      ROLES.MARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.HELPERSDASHBOARD}
                    component={HelpersDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.LOGINSDASHBOARD}
                    component={LoginsDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.BRANDSDASHBOARD}
                    component={BrandsDashboard}
                    roles={[ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.JRADMIN]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.SHOPSDASHBOARD}
                    component={ShopsDashboard}
                    roles={[ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.JRADMIN]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.PENALTIESDASHBOARD}
                    component={PenaltiesDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={`${ROUTES.CUISINESANDCOMBOSDASHBOARD}/cities/:city_id`}
                    component={CuisinesAndCombosDashboard}
                    roles={[ROLES.SUPERADMIN, ROLES.DIGITALMARKETER]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.CUISINESANDCOMBOSDASHBOARD}
                    component={CuisinesAndCombosDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                      ROLES.DIGITALMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.COOKEDITHISTORY}
                    component={CookEditHistory}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.TOP25COOKS}
                    component={Top25Cooks}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.COINDASHBOARD}
                    component={CoinDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.FINANCE
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.CAKESDASHBOARD}
                    component={CakesDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.SENIORMARKETER,
                      ROLES.MARKETER,
                      ROLES.DIGITALMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.DECORATIONSDASHBOARD}
                    component={DecorationsDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                      ROLES.SENIORMARKETER,
                      ROLES.MARKETER,
                      ROLES.DIGITALMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.HOTELSDASHBOARD}
                    component={HotelsDashboard}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                      ROLES.SUPERADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.BOOKINGCALENDAR}
                    component={BookingCalendar}
                    roles={[
                      ROLES.ADMIN,
                      ROLES.MARKETER,
                      ROLES.SUPERADMIN,
                      ROLES.JRADMIN,
                      ROLES.SENIORMARKETER,
                      ROLES.DIGITALMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.COUPONSDASHBOARD}
                    component={CouponsDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.MARKETER,
                      ROLES.SENIORMARKETER,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.KITSDASHBOARD}
                    component={KitsDashboard}
                    roles={[ROLES.SUPERADMIN, ROLES.ADMIN, ROLES.JRADMIN]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.CONTACTDASHBOARD}
                    component={ContactDashboard}
                    roles={[
                      ROLES.SUPERADMIN,
                      ROLES.ADMIN,
                      ROLES.BASIC,
                      ROLES.JRADMIN,
                    ]}
                  />
                  <PrivateRoute
                    exact
                    path={ROUTES.MONTHLYVIEW}
                    component={MonthlyView}
                    roles={[ROLES.SUPERADMIN]}
                  />
                  {/* <!-- End Content --> */}

                  {/* <!-- Footer Start --> */}
                  <Footer />
                  {/* <!-- end Footer --> */}
                </div>
              </Suspense>
              {/* <!-- content-page --> */}
            </div>
            {/* <!-- end wrapper--> */}
          </div>
        </div>
      )}
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    authUser: state.login.authUser,
  };
};

export default compose(
  withAuthentication,
  withAxios,
  connect(mapStateToProps)
)(App);
