export const SUPERADMIN = "SUPERADMIN";
export const ADMIN = "ADMIN";
export const BASIC = "BASIC";
export const MARKETER = "MARKETER";
export const JRADMIN = "JRADMIN";
export const DIGITALMARKETER = "DIGITALMARKETER";
export const MEMBER = "MEMBER";
export const HR = "HR";
export const SENIORMARKETER = "SENIORMARKETER";
export const FINANCE = "FINANCE";
